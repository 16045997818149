import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import {
  MDBSpinningPreloader,
  MDBBootstrapModulesPro,
  ToastModule,
  WavesModule,
  ButtonsModule,
} from 'ng-uikit-pro-standard';
import { LayoutsModule } from 'src/app/layouts/layouts.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from 'src/app/_clusters/reducers';
import { EffectsModule } from '@ngrx/effects';
import { httpInterceptorProviders } from 'src/app/_clusters/interceptors/https.interceptor';
import { AuthGuard } from 'src/app/_clusters/services/auth.guard';
import { PlyrModule } from 'ngx-plyr';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PreLoginComponent } from './layouts/pre-login.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CustomDerectiveModule } from './_clusters/custom-derective/custom-derective.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StudyplannerComponent } from 'projects/cma/src/app/pages/studyplanner_mf/studyplanner.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PreLoginComponent,
        loadChildren: () => import('src/app/auth/auth.module').then((s) => s.AuthModule),
      },
    ],
  },
  {
    path: 'lms',
    loadChildren: () => import('projects/cpa/src/app/app.module').then((s) => s.AppModuleCPA),
  },
  {
    path: 'cma',
    loadChildren: () => import('projects/cma/src/app/app.module').then((s) => s.AppModuleCMA),
  },
  {
    path: 'referral_mf/:canid/:srid',
    loadChildren: () => import('projects/cpa/src/app/pages/referral_mf/referral.module').then((s) => s.ReferralModule),
  },
  {
    path: 'study-planner_mf/:canid/:srid',
    component: StudyplannerComponent,
    loadChildren: () => import('projects/cma/src/app/pages/studyplanner_mf/studyplanner.module').then((s) => s.StudyplannerModule),
  },
  {
    path: 'mentoring',
    loadChildren: () =>
      import('projects/mentoring/src/app/app.module').then((s) => s.AppModuleMentoring),
  },
  {
    path: 'qr',
    loadChildren: () => import('projects/qr/src/app/app.module').then((s) => s.AppModuleQR),
  },
  {
    path: 'milescpe',
    loadChildren: () => import('projects/fof/src/app/app.module').then((s) => s.AppModuleFoF),
  },
  {
    path: 'feedback',
    loadChildren: () => import('src/app/auth/livefeedback/livefeedback.module').then((s) => s.LivefeedbackModule),
  },
  {
    path: 'mentoringfeedback',
    loadChildren: () => import('src/app/auth/mentoringfeedback/mentoringfeedback.module').then((s) => s.MentoringfeedbackModule),
  },
  {
    path: 'study-lounge',
    loadChildren: () =>
      import('projects/study-lounge/src/app/app.module').then((s) => s.AppModuleStudyLounge),
  },
  // {
  //   path: 'fof/:name/:email',
  //   loadChildren: () => import("projects/fof/src/app/app.module").then((s) => s.AppModuleFoF)

  // },
  // {
  //   path: 'mac',
  //   loadChildren: () => import("projects/mac/src/app/app.module").then((s) => s.AppModuleMAC)
  // },
  {
    path: 'kickout',
    loadChildren: () =>
      import('projects/cpa/src/app/pages/global/kickout/kickout.module').then(
        (s) => s.KickoutModule
      ),
  },
  { path: 'kickout', redirectTo: '/', pathMatch: 'full' },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

const dbConfig: DBConfig = {
  name: 'cmacuation',
  version: 1,
  objectStoresMeta: [
    {
      store: 'credentials',
      storeConfig: { keyPath: 'data', autoIncrement: true },
      storeSchema: [{ name: 'name', keypath: 'name', options: { unique: false } }],
    },
  ],
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    DragDropModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      // initialNavigation: 'enabled',
    }),
    FormsModule,
    LayoutsModule,
    // DeviceDetectorModule.forRoot(),
    // PlyrModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    WavesModule,
    ButtonsModule,
    // BrowserAnimationsModule,
    NoopAnimationsModule,
    // NgxSkeletonLoaderModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CustomDerectiveModule,
  ],
  providers: [MDBSpinningPreloader, httpInterceptorProviders, AuthGuard],
  bootstrap: [AppComponent],
  // schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
