import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(private router: Router) { }

  success(message: any, timer?: number) {
    Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      confirmButtonText: 'Ok',
      timer: timer
    });
  }

  registerWebinar(tittle, message) {
    Swal.fire({
      title: tittle,
      html: message,
      icon: 'error',
      confirmButtonText: 'Ok'
    });
  }

  alert(message: any) {
    Swal.fire({
      title: 'Alert!',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Ok'
    });
  }

  error(message: any, timer?: number) {
    Swal.fire({
      title: 'Error',
      text: message,
      icon: 'error',
      confirmButtonText: 'Ok',
      timer: timer
    });
  }

  warning(message: any) {
    Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Ok'
    });
  }

  info(message: any) {
    Swal.fire({
      title: 'Info',
      text: message,
      icon: 'info',
      confirmButtonText: 'Ok'
    });
  }

  appointmentInformation(title: string, msgIcon: any, message: any) {
    Swal.fire({
      title: title,
      html: message,
      icon: msgIcon,
      confirmButtonText: 'Ok'
    });
  }

  noAccessPopup(message: any) {
    Swal.fire({
      title: 'Error',
      text: message,
      icon: 'error',
      confirmButtonText: 'Ok'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.router.navigate(['/cma/lms/launchpad']);
      }
    })
  }
}
